export default {
  methods: {
    loadExternalScript: (url, options) => {
      const script = document.createElement("script")

      script.type = "text/javascript"
      script.src = url
      script.async = true
      Object.assign(script, options)

      script.addEventListener("error", (error) => {
        console.error(error)
      })

      document.getElementsByTagName("head")[0].appendChild(script)
    },
  },
}
